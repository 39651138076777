import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Landing from "./Pages/Landing";
import ContactPage from "./Pages/ContactPage";
import AboutPage from "./Pages/AboutPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Blog from "./Pages/Blog";
import LoginPage from "./Admin/LoginPage";
import Dashboard from "./Admin/Dashboard";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
    });
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/admin" element={<LoginPage />} />
        <Route path="/admin/*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
