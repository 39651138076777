import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { MilitaryTechRounded } from "@mui/icons-material";

export default function WhyUS(props) {
  return (
    <Box sx={{ py: 8, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Grid container xs={12} spacing={10} className="left">
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ fontWeight: 900, color: "#011b46" }}
              variant="h2"
              component="h1"
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="0"
            >
              Our{" "}
              <span
                data-aos="fade-up"
                data-aos-delay="300"
                style={{ color: "#fa541c" }}
              >
                Values
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ fontWeight: 400, fontSize: "large", color: "#011b46" }}
              variant="body1"
              paragraph
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="300"
            >
              At Finnai Tech Solutions, we innovate to elevate your business
              with cutting-edge software solutions.
            </Typography>
          </Grid>
        </Grid>
        <Grid my={10} container spacing={4} alignItems="center">
          <Grid item xs={12} md={3} data-aos="fade-right" data-aos-delay="600">
            <MilitaryTechRounded
              sx={{
                fontSize: "84px",
                display: "block",
                margin: "auto",
                color: "#fa541c",
              }}
              gutterBottom
            />
            <Typography
              variant="h6"
              sx={{ color: "#011b46", textAlign: "center" }}
              gutterBottom
            >
              Innovation at Heart
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="body1">
              We thrive on creativity and forward-thinking, delivering
              innovative solutions that push the boundaries of technology.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} data-aos="fade-right" data-aos-delay="900">
            <MilitaryTechRounded
              sx={{
                fontSize: "84px",
                display: "block",
                margin: "auto",
                color: "#fa541c",
              }}
              gutterBottom
            />
            <Typography
              variant="h6"
              sx={{ color: "#011b46", textAlign: "center" }}
              gutterBottom
            >
              Customer-Centric Approach
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="body1">
              Our clients are at the core of everything we do. We build strong
              partnerships, ensuring our solutions align perfectly with your
              needs.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} data-aos="fade-right" data-aos-delay="1200">
            <MilitaryTechRounded
              sx={{
                fontSize: "84px",
                display: "block",
                margin: "auto",
                color: "#fa541c",
              }}
              gutterBottom
            />
            <Typography
              variant="h6"
              sx={{ color: "#011b46", textAlign: "center" }}
              gutterBottom
            >
              Commitment to Quality
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="body1">
              We are dedicated to excellence. Every project is a testament to
              our unwavering commitment to deliver superior quality software.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} data-aos="fade-right" data-aos-delay="1500">
            <MilitaryTechRounded
              sx={{
                fontSize: "84px",
                display: "block",
                margin: "auto",
                color: "#fa541c",
              }}
              gutterBottom
            />
            <Typography
              variant="h6"
              sx={{ color: "#011b46", textAlign: "center" }}
              gutterBottom
            >
              Integrity and Transparency
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="body1">
              We uphold the highest standards of integrity and transparency in
              all our dealings, fostering trust and long-term relationships with
              our clients.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
