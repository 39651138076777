import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  TablePagination,
  Link,
} from "@mui/material";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { Link as RouterLink } from "react-router-dom";

export default function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchBlogs();
  }, [page, rowsPerPage, searchText]);

  const fetchBlogs = () => {
    setLoading(true);
    const url = searchText
      ? `/api/blogs/wildsearch/${searchText}`
      : `/api/blogs/myblogs/${rowsPerPage}/${page * rowsPerPage}`;
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("Error fetching blogs");
      })
      .then((data) => {
        setLoading(false);
        setBlogs(data?.data);
        setCount(data?.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleSearchChange = debounce((event) => {
    setSearchText(event.target.value);
    setPage(0);
  }, 500);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ flexGrow: 1 }} variant="h6">
            Blog Posts
          </Typography>
          <TextField
            label="Search Blogs..."
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            sx={{ marginRight: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="create"
          >
            Create New Blog
          </Button>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Feature</TableCell>
                  <TableCell>Theme</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Content</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blogs.map((blog) => (
                  <TableRow key={blog.ID}>
                    <TableCell>
                      {dayjs(blog.Date).format("MMMM D, YYYY h:mm A")}
                    </TableCell>
                    <TableCell>{blog.Feature}</TableCell>
                    <TableCell>{blog.Theme}</TableCell>
                    <TableCell>{blog.Status ? "Active" : "Inactive"}</TableCell>
                    <TableCell align="right">
                      {blog.BlogsContent.slice(0, 50)}...
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
}
