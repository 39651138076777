import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Link,
  Button,
  Box,
  TextField,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Instagram, Facebook, LinkedIn } from "@mui/icons-material";
import logo from "../../assets/images/logo.svg";

export default function Footer(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await fetch("/api/newsletters/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email: email }),
      });

      if (response.ok) {
        setSuccess("Thank you for subscribing!");
        setEmail("");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } else {
        throw new Error("Failed to subscribe");
      }
    } catch (err) {
      setError("Failed to subscribe, please try again later.");
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: "#011b46", py: 6, color: "white" }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Section 1: Logo and Social Media */}
          <Grid item xs={12} md={3}>
            <Box mb={2}>
              <img src={logo} alt="Logo" style={{ maxWidth: "100%" }} />
            </Box>
            <Typography
              data-aos="fade-up"
              sx={{ fontWeight: 900, textAlign: "left", color: "#4fc3f1" }}
              variant="h6"
              gutterBottom
            >
              Finnai
              <br /> <span style={{ color: "#FA541C" }}>Tech Solutions</span>
            </Typography>
            <Box>
              <IconButton
                href="https://www.instagram.com/finnaitech_ke?igsh=MXdoczFpZ21wZWw0Yg%3D%3D&utm_source=qr"
                target="_blank"
                sx={{
                  color: "#4fc3f1",
                  "&:hover": { color: "#FA541C" },
                }}
                data-aos="fade-up"
              >
                <Instagram fontSize="large" />
              </IconButton>
              <IconButton
                href="https://www.facebook.com/profile.php?id=100083178228458"
                target="_blank"
                sx={{
                  color: "#4fc3f1",
                  "&:hover": { color: "#FA541C" },
                }}
                size="large"
                data-aos="fade-up"
              >
                <Facebook fontSize="large" />
              </IconButton>
              <IconButton
                href="https://www.linkedin.com/company/71558624"
                target="_blank"
                sx={{
                  color: "#4fc3f1",
                  "&:hover": { color: "#FA541C" },
                }}
                size="large"
                data-aos="fade-up"
              >
                <LinkedIn fontSize="large" />
              </IconButton>
            </Box>
          </Grid>

          {/* Section 2: Sitemap */}
          <Grid item xs={12} md={3}>
            <Typography data-aos="fade-up" variant="h6" gutterBottom>
              Sitemap
            </Typography>
            <Link
              href="/"
              underline="none"
              color="inherit"
              display="block"
              sx={{
                my: 1,
                transition: "color 0.3s ease",
                "&:hover": { color: "#FA541C" },
              }}
              data-aos="fade-up"
            >
              Home
            </Link>
            <Link
              href="products"
              underline="none"
              color="inherit"
              display="block"
              sx={{
                my: 1,
                transition: "color 0.3s ease",
                "&:hover": { color: "#FA541C" },
              }}
              data-aos="fade-up"
            >
              Products
            </Link>
            <Link
              href="pricing"
              underline="none"
              color="inherit"
              display="block"
              sx={{
                my: 1,
                transition: "color 0.3s ease",
                "&:hover": { color: "#FA541C" },
              }}
              data-aos="fade-up"
            >
              Pricing
            </Link>
            <Link
              href="blog"
              underline="none"
              color="inherit"
              display="block"
              sx={{
                my: 1,
                transition: "color 0.3s ease",
                "&:hover": { color: "#FA541C" },
              }}
              data-aos="fade-up"
            >
              Blog
            </Link>
          </Grid>

          {/* Section 3: Legal Stuff */}
          <Grid item xs={12} md={3}>
            <Typography data-aos="fade-up" variant="h6" gutterBottom>
              Legal Stuff
            </Typography>
            <Link
              href="privacy"
              underline="none"
              color="inherit"
              display="block"
              sx={{
                my: 1,
                transition: "color 0.3s ease",
                "&:hover": { color: "#FA541C" },
              }}
              data-aos="fade-up"
            >
              Privacy Policy
            </Link>
            <Link
              href="terms"
              underline="none"
              color="inherit"
              display="block"
              sx={{
                my: 1,
                transition: "color 0.3s ease",
                "&:hover": { color: "#FA541C" },
              }}
              data-aos="fade-up"
            >
              Terms and Conditions
            </Link>
          </Grid>

          {/* Section 4: Contact Form */}
          <Grid item xs={12} md={3}>
            <Typography data-aos="fade-up" variant="h6" gutterBottom>
              Allow us to keep in touch with you.
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                type="email"
                name="reply_to"
                placeholder="Email Address"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                data-aos="fade-up"
                sx={{ mb: 2, backgroundColor: "white", borderRadius: 1 }}
              />
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              {success && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  {success}
                </Alert>
              )}
              <Button
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: 2,
                  backgroundColor: "#FA541C",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FF7A45",
                  },
                  minWidth: 200,
                  py: 1,
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress /> : "Submit"}
              </Button>
            </form>
          </Grid>
        </Grid>
        {/* Copyright Section */}
        <Box data-aos="fade-up" mt={4} textAlign="center">
          <Typography
            sx={{ hover: "#FF7A45", cursor: "pointer" }}
            variant="body2"
            color="inherit"
            onClick={() => {
              window.open("/admin");
            }}
          >
            &copy; {new Date().getFullYear()} Finnai Tech Solutions. All rights
            reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
