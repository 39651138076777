import React from "react";
import { Container, Typography, Box, Grid, Paper } from "@mui/material";

const BlogPostPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h3" gutterBottom>
          Blog Title
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Author Name | Published Date
        </Typography>
        <Box sx={{ my: 4 }}>
          <img
            src="https://via.placeholder.com/800x400"
            alt="Blog Cover"
            style={{ width: "100%", borderRadius: 8 }}
          />
        </Box>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae
          scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices
          nec congue eget, auctor vitae massa.
        </Typography>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae
          scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices
          nec congue eget, auctor vitae massa.
        </Typography>
        {/* Add more content as needed */}
      </Paper>
    </Container>
  );
};

export default BlogPostPage;
