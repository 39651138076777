// Home.js
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TopItem } from "../home/TopItem";
import Pie from "../home/Pie";
import Bar from "../home/Bar";

function Home() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetch(`/api/activities/stats`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("");
      })
      .then((data) => {
        setStats(data);
      })
      .catch((e) => {});
  }, []);

  return (
    <Box marginTop={8} padding={1}>
      <Grid container spacing={3}>
        <Grid xs={6} sm={3} md={3}>
          <TopItem title="Client Visits" stats={stats} trend="up" />
        </Grid>
        <Grid xs={6} sm={3} md={3}>
          <TopItem title="Quotes" stats={stats} trend="up" />
        </Grid>
        <Grid xs={6} sm={3} md={3}>
          <TopItem title="New Prospects" stats={stats} trend="up" />
        </Grid>
        <Grid xs={6} sm={3} md={3}>
          <TopItem title="Sales Closed" stats={stats} trend="up" />
        </Grid>
        <Grid md={8} xs={12}>
          <Bar
            chartSeries={[
              {
                name: "This year",
                data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20],
              },
            ]}
            sx={{ height: "100%" }}
          />
        </Grid>
        <Grid md={4} xs={12}>
          <Pie
            title="Technical Support"
            stats={stats}
            sx={{ height: "100%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
