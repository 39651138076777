import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  SliderValueLabel,
} from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  Api,
  AppRegistrationRounded,
  FlipToFront,
  Payment,
  Person2Rounded,
  SwapHorizontalCircleRounded,
} from "@mui/icons-material";

export default function HowItWorks(props) {
  return (
    <Box sx={{ py: 8, backgroundColor: "white" }}>
      <Container>
        <Grid container xs={12} alignItems="center">
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ fontWeight: 900, color: "#011b46" }}
                variant="h2"
                component="h1"
                gutterBottom
                data-aos="fade-up"
                data-aos-delay="0"
              >
                Who <span style={{ color: "#fa541c" }}>WE</span> Are
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "large", color: "#011b46" }}
                variant="body1"
                paragraph
                gutterBottom
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Software Solutions for your Business
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} my={2} alignItems="center">
            <Grid container spacing={5}>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex" }}>
                  <Api
                    sx={{ fontSize: "44px", color: "#fa541c" }}
                    gutterBottom
                    data-aos="fade-right"
                    data-aos-delay="300"
                  />
                  <Typography
                    variant="h6"
                    sx={{ color: "#011b46", marginLeft: 1, marginTop: "auto" }}
                    gutterBottom
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    Vision
                  </Typography>
                </Box>

                <Typography
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  Our vision is to digitalize business operations through
                  cutting edge technology and innovation.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex" }}>
                  <Api
                    sx={{ fontSize: "44px", color: "#fa541c" }}
                    gutterBottom
                    data-aos="fade-right"
                    data-aos-delay="300"
                  />
                  <Typography
                    variant="h6"
                    sx={{ color: "#011b46", marginLeft: 1, marginTop: "auto" }}
                    gutterBottom
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    Mission
                  </Typography>
                </Box>

                <Typography
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  Our mission is to provide disruptive solutions for cliche`
                  business operations.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex" }}>
                  <Api
                    sx={{ fontSize: "44px", color: "#fa541c" }}
                    gutterBottom
                    data-aos="slide-right"
                    data-aos-delay="300"
                  />
                  <Typography
                    variant="h6"
                    sx={{ color: "#011b46", marginLeft: 1, marginTop: "auto" }}
                    gutterBottom
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    Values
                  </Typography>
                </Box>

                <Typography
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  We value teamwork, integrity, and commitment.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
