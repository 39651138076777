import React, { useState } from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import { CheckRounded, Euro } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import EnquiryDialog from "./EnquiryDialog"; // Import the EnquiryDialog component

export default function Pricing(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");

  const handleOpenDialog = (title) => {
    setSelectedTitle(title);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{ backgroundColor: "white", py: 4 }}>
      <Container
        maxWidth="lg"
        style={{
          marginTop: "2rem",
          backgroundColor: "white",
          marginBottom: "2rem",
        }}
        gutterBottom
      >
        <Typography
          sx={{ textAlign: "center" }}
          variant="h4"
          style={{ marginBottom: "2rem" }}
          gutterBottom
          data-aos="fade-up"
          data-aos-delay="0"
        >
          The Right Plan For You
        </Typography>
        <Typography
          sx={{ textAlign: "center" }}
          variant="body1"
          style={{ marginBottom: "1rem" }}
          gutterBottom
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Choose the perfect plan for your needs. Always flexible to grow
        </Typography>
        <Typography
          sx={{ textAlign: "center", color: "#FA541C", marginBottom: "2rem" }}
          variant="h5"
          gutterBottom
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Website Development
        </Typography>
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            md={4}
            gutterBottom
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <Card
              sx={{
                borderRadius: 6,
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    color: "#FA541C",
                  }}
                  display="flex"
                  justifyContent="center"
                  mb={2}
                >
                  <Euro sx={{ fontSize: "64px", marginTop: "0px" }} />
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  Personal Websites
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Design + Development
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  KSh 50,000
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Development Only
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  KSh 35,000
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Design Only
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                  paragraph
                >
                  KSh 15,000
                </Typography>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    SEO Optimization
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Social Media Integration
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Free Domain Registration (1 year)
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Free Hosting (1 year)
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Contact Form Integration
                  </Typography>
                </Grid>

                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    24/7 Support
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Up to 3 revisions (upon approval)
                  </Typography>
                </Grid>

                <Button
                  sx={{ width: "100%", mt: 2 }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenDialog("Personal Websites")}
                >
                  Enquire
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            py={5}
            item
            xs={12}
            md={4}
            gutterBottom
            data-aos="zoom-in"
            data-aos-delay="600"
          >
            <Card
              sx={{
                borderRadius: 6,
                boxShadow: "0px 16px 40px rgba(0,0,0,0.4)",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    color: "green",
                  }}
                  display="flex"
                  justifyContent="center"
                  mb={2}
                >
                  <Euro sx={{ fontSize: "100px" }} />
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                  }}
                  variant="h5"
                  gutterBottom
                  paragraph
                >
                  SMEs
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Design + Development
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                  paragraph
                >
                  KSh 70,000
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Development Only
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                  paragraph
                >
                  KSh 50,000
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Design Only
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                  paragraph
                >
                  KSh 20,000
                </Typography>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    SEO Optimization
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Social Media Integration
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Free Domain Registration (1 year)
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Free Hosting (1 year)
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Contact Form Integration
                  </Typography>
                </Grid>

                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    24/7 Support
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Up to 3 revisions (upon approval)
                  </Typography>
                </Grid>

                <Button
                  sx={{ width: "100%", mt: 2 }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenDialog("SMEs")}
                >
                  Enquire
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            gutterBottom
            data-aos="zoom-in"
            data-aos-delay="900"
          >
            <Card
              sx={{
                borderRadius: 6,
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    color: "#FA541C",
                  }}
                  display="flex"
                  justifyContent="center"
                  mb={2}
                >
                  <Euro sx={{ fontSize: "64px", marginTop: "0px" }} />
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                  }}
                  variant="h6"
                  gutterBottom
                  paragraph
                >
                  Corporate/Organizations
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Design + Development
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  KSh 150,000
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Development Only
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  KSh 100,000
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#011b46",
                    fontSize: "medium",
                  }}
                  variant="h6"
                >
                  Design Only
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FA541C",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  KSh 50,000
                </Typography>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    SEO Optimization
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Social Media Integration
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Free Domain Registration (1 year)
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Free Hosting (1 year)
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Contact Form Integration
                  </Typography>
                </Grid>

                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    24/7 Support
                  </Typography>
                </Grid>
                <Grid container spacing={1} gutterBottom>
                  <CheckRounded
                    sx={{ color: "green", marginRight: 1 }}
                    item
                    xs={1}
                  />
                  <Typography
                    item
                    variant="body1"
                    style={{ fontSize: "small" }}
                    paragraph
                  >
                    Up to 3 revisions (upon approval)
                  </Typography>
                </Grid>

                <Button
                  sx={{ width: "100%", mt: 2 }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenDialog("Corporate/Organizations")}
                >
                  Enquire
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Enquiry dialog component */}
      <EnquiryDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={selectedTitle}
      />
    </Box>
  );
}
