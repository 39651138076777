import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  Stack,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const HeadingBlock = ({ content, onChange }) => (
  <TextField
    placeholder="Enter heading..."
    value={content}
    onChange={(e) => onChange(e.target.value)}
    fullWidth
    sx={{ my: 2 }}
    InputProps={{
      sx: {
        border: "1px solid white", // Bottom border only
        borderRadius: 0, // No rounded corners
        fontSize: "1.5rem",
        outline: "none",
        ":hover": { border: "none" },
        fontWeight: "bold",
        padding: "8px 0", // Padding to adjust text positioning
      },
      disableUnderline: true, // Disable the default underline
    }}
  />
);

const SubheadingBlock = ({ content, onChange }) => (
  <TextField
    placeholder="Enter subheading..."
    value={content}
    onChange={(e) => onChange(e.target.value)}
    fullWidth
    sx={{ my: 2 }}
    InputProps={{
      sx: {
        borderBottom: "1px solid white",
        borderRadius: 0,
        fontSize: "1.25rem",
        fontWeight: "bold",
        padding: "8px 0",
      },
      disableUnderline: true,
    }}
  />
);

const ParagraphBlock = ({ content, onChange }) => (
  <TextField
    placeholder="Enter paragraph..."
    value={content}
    onChange={(e) => onChange(e.target.value)}
    multiline
    rows={4}
    fullWidth
    sx={{ my: 2 }}
    InputProps={{
      sx: {
        borderBottom: "1px solid white",
        borderRadius: 0,
        fontSize: "1rem",
        lineHeight: "1.5",
        padding: "8px 0",
      },
      disableUnderline: true,
    }}
  />
);

const ImageBlock = ({ content, onChange }) => (
  <TextField
    placeholder="Enter image URL..."
    value={content}
    onChange={(e) => onChange(e.target.value)}
    fullWidth
    sx={{ my: 2 }}
    InputProps={{
      sx: {
        borderBottom: "1px solid white",
        borderRadius: 0,
        fontSize: "1rem",
        padding: "8px 0",
      },
      disableUnderline: true,
    }}
  />
);

const LinkBlock = ({ content, onChange }) => (
  <TextField
    placeholder="Enter link URL..."
    value={content}
    onChange={(e) => onChange(e.target.value)}
    fullWidth
    sx={{ my: 2 }}
    InputProps={{
      sx: {
        borderBottom: "1px solid white",
        borderRadius: 0,
        fontSize: "1rem",
        color: "blue",
        textDecoration: "underline",
        padding: "8px 0",
      },
      disableUnderline: true,
    }}
  />
);

// Blog Creation Component
export default function CreateBlog() {
  const [date, setDate] = useState("");
  const [feature, setFeature] = useState(1);
  const [theme, setTheme] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedBlockType, setSelectedBlockType] = useState("");
  const navigate = useNavigate();

  const addBlock = (blockType) => {
    setBlocks([...blocks, { type: blockType, content: "" }]);
    setSelectedBlockType(blockType);
  };

  const updateBlockContent = (index, content) => {
    const updatedBlocks = [...blocks];
    updatedBlocks[index].content = content;
    setBlocks(updatedBlocks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const serializedContent = JSON.stringify(blocks);

    const newBlog = {
      Date: date,
      Feature: feature,
      Theme: theme,
      BlogsContent: serializedContent,
      Status: status,
    };

    fetch("/api/blogs/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newBlog),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
        } else {
          setError(data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("Failed");
      });
  };

  return (
    <Box marginTop={8}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Create New Blog
        </Typography>
        <Divider sx={{ my: 2 }} />
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Feature"
              type="number"
              value={feature}
              onChange={(e) => setFeature(parseInt(e.target.value, 10))}
              required
              fullWidth
            />
            <TextField
              label="Theme"
              value={theme}
              onChange={(e) => setTheme(e.target.value)}
              required
              marginBottom={8}
              fullWidth
            />
            {/* Render Blocks */}
            <Box>
              {blocks.map((block, index) => {
                let BlockComponent;
                switch (block.type) {
                  case "heading":
                    BlockComponent = HeadingBlock;
                    break;
                  case "subheading":
                    BlockComponent = SubheadingBlock;
                    break;
                  case "paragraph":
                    BlockComponent = ParagraphBlock;
                    break;
                  case "image":
                    BlockComponent = ImageBlock;
                    break;
                  case "link":
                    BlockComponent = LinkBlock;
                    break;
                  default:
                    return null;
                }

                return (
                  <BlockComponent
                    key={index}
                    content={block.content}
                    onChange={(content) => updateBlockContent(index, content)}
                  />
                );
              })}
            </Box>

            {/* Block Selection Cards */}
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" gutterBottom>
                Select Block Type
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("heading")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Heading
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("subheading")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Subheading
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("paragraph")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Paragraph
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("image")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Image
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("link")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Link
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("link")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Break
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Box>

            {error && <Alert color="warning">{error}</Alert>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ marginTop: "2rem" }}
            >
              {loading ? <CircularProgress /> : "Create Blog"}
            </Button>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
}
