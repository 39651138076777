import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Api, PlusOne } from "@mui/icons-material";

export default function Portfolio(props) {
  return (
    <Box sx={{ py: 8, backgroundColor: "white" }}>
      <Container>
        <Grid container xs={12} alignItems="center">
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ fontWeight: 900, color: "#011b46" }}
                variant="h2"
                component="h1"
                gutterBottom
                data-aos="fade-up"
              >
                Our <span style={{ color: "#fa541c" }}>Portfolio</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "large", color: "#011b46" }}
                variant="body1"
                paragraph
                gutterBottom
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Software Solutions for your Business
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={5} md={2} my={2} alignItems="center">
            <Grid container spacing={5} alignItems="center">
              <Grid item xs={12} md={1} data-aos="fade-up" data-aos-delay="300">
                <Box sx={{ display: "flex", width: "fit-content" }}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#011b46",
                      fontWeight: 800,
                      margin: "auto",
                    }}
                  >
                    20
                  </Typography>
                  <PlusOne sx={{ color: "#fa541c" }} />
                </Box>
                <Typography variant="body1">Projects</Typography>
              </Grid>
              <Grid item xs={12} md={1} data-aos="fade-up" data-aos-delay="600">
                <Box sx={{ display: "flex", width: "fit-content" }}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#011b46",
                      fontWeight: 800,
                      marginRight: "auto",
                    }}
                  >
                    10
                  </Typography>
                  <PlusOne sx={{ color: "#fa541c" }} />
                </Box>
                <Typography variant="body1">Happy Clients</Typography>
              </Grid>
              <Grid item xs={12} md={1} data-aos="fade-up" data-aos-delay="900">
                <Box sx={{ display: "flex", width: "fit-content" }}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#011b46",
                      fontWeight: 800,
                      margin: "auto",
                    }}
                  >
                    7
                  </Typography>
                  <PlusOne sx={{ color: "#fa541c" }} />
                </Box>
                <Typography variant="body1">Years of Experience</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4} my={2} alignItems="center">
            <Grid container spacing={13}>
              <Grid item xs={12} md={3} data-aos="fade-up" data-aos-delay="300">
                <Typography variant="body1">
                  Delivering innovative solutions across 20+ successful
                  projects."
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} data-aos="fade-up" data-aos-delay="600">
                <Typography variant="body1">
                  Trusted by over 10 satisfied clients in Kenya.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} data-aos="fade-up" data-aos-delay="900">
                <Typography variant="body1">
                  Empowering businesses with over 7 years of industry
                  experience.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
