import React from "react";
import { Container, Typography, Button, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import image from "../../assets/images/landing.png";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { PlayCircle } from "@mui/icons-material";
import SlideUpList from "../Utils/SlideUp";

export default function Hero(props) {
  return (
    <Box sx={{ py: 16, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Grid spacing={4} className="div2equal" alignItems="center">
          <Grid
            sx={{ order: { xs: 1, md: 0 } }}
            item
            xs={12}
            md={6}
            className="left"
          >
            <Typography
              data-aos="fade-up"
              sx={{ fontWeight: 900, color: "#011b46" }}
              variant="h2"
              component="h1"
              gutterBottom
            >
              We Tech <span style={{ color: "#FA541C" }}>IT</span> Easy
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: "large", color: "#011b46" }}
              variant="body1"
              paragraph
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="300"
            >
              Finnai Tech Solutions is an IT consulting company that is based in
              Kenya. We specialize in design, development, branding, and other
              IT Services.
            </Typography>

            <Grid data-aos="fade-up" data-aos-delay="600" container>
              <ColorButton
                item
                sx={{ my: 2 }}
                variant="contained"
                color="primary"
              >
                Enquire
              </ColorButton>

              <Box
                item
                sx={{
                  display: "inline-block",
                  margin: "auto auto auto 1rem",
                  height: "fit-content",
                  border: "1px solid #FA541C",
                  borderRadius: "5px",
                  color: "#FA541C",
                }}
              >
                <IconButton aria-label="play" sx={{ color: "#FA541C" }}>
                  <PlayCircle /> <Typography>See our Work...</Typography>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Grid
            sx={{ order: { xs: 0, md: 1 }, marginBottom: { xs: 10, md: 0 } }}
            item
            xs={12}
            md={6}
          >
            <img
              src={image}
              alt=""
              style={{
                maxHeight: "300px",
                maxWidth: "70vw",
                objectFit: "contain",
                display: "block",
                margin: "auto",
                filter: "drop-shadow(0px 56px 80px rgba(0, 0, 0, 0.4))",
              }}
              data-aos="slide-right"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#011b46"),
  backgroundColor: "#011b46",
  "&:hover": {
    backgroundColor: "#FA541C",
  },
}));

const content = [
  {
    title: "We Tech it Easy",
    desc: `Finnai Tech Solutions is an IT consulting company that is based in Kenya. We specialize in design, development, branding, and other IT Services.`,
  },
  {
    title: "Discover the best software solutions in Kenya",
    desc: "Finnai Tech provides the best Software solutions that you require to boost your business.",
  },
  {
    title: "Your apps, your style, create them any way you want",
    desc: "Finnai Tech features swiftly made, easily edited, beautifully deployed apps",
  },
  {
    title:
      "If you don’t create an app someone will create an app for your customer",
    desc: "Finnai Tech provides for a simple, magical way to connect with people",
  },
  {
    title: "Making the  world better one app at a time",
    desc: "Finnai Tech make apps that feel good to use and bring joy to those you’re offering them to",
  },
];
